.App {
  font-family: "Roboto", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 16px;
  flex-direction: column;
}

.container {
  width: 100%;
  max-width: 600px;
  background-color: white;
  border-radius: 8px;
  padding: 32px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 0 16px;
}


h1 {
  font-size: 36px;
  font-weight: 500;
  color: grey;
  margin-bottom: 24px;
}

.input-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
}

.input-field {
  font-size: 18px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-grow: 1;
  margin-right: 8px;
  margin-bottom: 8px;
  flex-basis: calc(50% - 8px);
}

.input-field:last-child {
  margin-right: 0;
}

.button {
  font-size: 18px;
  padding: 8px 16px;
  background-color: rgb(80, 80, 80);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.button:hover {
  background-color: #303030;
}

.button:disabled {
  background-color: #9fa8da;
  cursor: not-allowed;
}

.result-container {
  margin-top: 24px;
}

h2 {
  font-size: 24px;
  font-weight: 500;
  color: #3f51b5;
  margin-bottom: 16px;
}

p {
  font-size: 18px;
}

@media (max-width: 480px) {
  .container {
    margin: 0;
    border-radius: 0;
  }

  .input-field {
    flex-basis: 100%;
    margin-right: 0;
  }
}
